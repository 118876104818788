<template>
  <v-container>
    <v-row v-if="!error">
      <v-col>
        <img width="200px" src="/img/logopm.png"  alt="logo previ meteo"/><br/>
        87 Quai des Queyries<br />
        33100 Bordeaux<br />
        05 53 93 43 06<br />
      </v-col>
      <v-col class="pt-12">
        <b contenteditable="true">{{ entreprise.user_company }}</b><br />

        <span contenteditable="true"> {{ entreprise.user_adresse  }}</span><br/>
        <span contenteditable="true"> {{ entreprise.user_cp  }}</span>
        <span contenteditable="true"> {{ entreprise.user_ville  }}</span>
      </v-col>
      <v-col cols="12">
        <b>Période</b> :
        <!-- Get date range period inside dailyData -->
        {{ moment(dailyData[0].date).format('DD-MM-YYYY') }} au {{ moment(dailyData[dailyData.length -
      1].date).format('DD-MM-YYYY')
        }}
        <br />
        <b>Chantier</b> : <span contenteditable="true">{{ $route.query.chantierName }}</span><br />
        <span v-if="$route.query?.reference"><b>Vos réf.</b> : <span contenteditable="true">{{ $route.query.reference }}</span><br /></span>
        <b>Station</b> : <span contenteditable="true">{{ stationInfos.NOM }} ({{ $route.params.id_station }})</span><br />
      </v-col>
      <v-col cols="12">
        <div v-if="ceils.needTemp" >
          <span>
            Température
            <span v-if="ceils.tempmin">&lt; <span contenteditable="true" @blur="updateAlertData($event, 'tempmin')">{{ceils.tempmin}}</span>°C</span>
            <span v-if="ceils.tempmin && ceils.tempmax"> ou </span>
            <span v-if="ceils.tempmax">&gt; <span contenteditable="true" @blur="updateAlertData($event, 'tempmax')">{{ceils.tempmax}}</span>°C</span>
            entre
            <span contenteditable="true" @blur="updateAlertData($event, 'startTempTime')">{{ceils.startTempTime}}</span>h et
            <span contenteditable="true" @blur="updateAlertData($event, 'endTempTime')">{{ceils.endTempTime}}</span>h
          </span>
          <div>
            <table>
              <tbody>
                <tr v-for="(ligne, indexLigne) in temperatureMatrix"
                    :key="'ligne-' + indexLigne"
                    :style="coloredLines(temperatureMatrix[indexLigne][0],temperatureMatrix[indexLigne][1]) ? 'background-color: rgb(166,201,236);' : ''"
                >
                  <td
                      v-for="(value, indexColonne) in ligne"
                      :key="'colonne-' + indexColonne"
                      @click="toggleColor(indexLigne, indexColonne, 'temp')"
                      :style="getBackgroundColor(indexLigne, indexColonne,'temp')"
                  >
                    <span
                        contenteditable="true"
                        @blur="updateTempData($event, indexLigne, indexColonne, 'temp')"
                        @click="toggleColor( indexLigne, indexColonne, 'temp')"
                    >
                      {{ value ? value : '-' }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="pagebreak"> </div>
        </div>
        <div v-if="ceils.needWind">
          <span>
            Vitesse du vent (en km/h)
            <span v-if="ceils.wind">&gt; <span contenteditable="true" @blur="updateAlertData($event, 'wind')">{{ceils.wind}}</span> km/h</span>
            <span v-if="ceils.wind"> pendant <span contenteditable="true" @blur="updateAlertData($event, 'windDuration')">{{ceils.windDuration}}</span>h</span>
            entre
            <span contenteditable="true" @blur="updateAlertData($event, 'startWindTime')">{{ceils.startWindTime}}</span>h et
            <span contenteditable="true" @blur="updateAlertData($event, 'endWindTime')">{{ceils.endWindTime}}</span>h
          </span>
          <div>
            <table>
              <tbody>
              <tr v-for="(ligne, indexLigne) in windMatrix"
                  :key="'ligne-' + indexLigne"
                  :style="coloredLines(windMatrix[indexLigne][0],windMatrix[indexLigne][1]) ? 'background-color: rgb(166,201,236);' : ''"
              >
                <td
                    v-for="(value, indexColonne) in ligne"
                    :key="'colonne-' + indexColonne"
                    @click="toggleColor(indexLigne, indexColonne, 'wind')"
                    :style="getBackgroundColor(indexLigne, indexColonne, 'wind')"
                >
                    <span
                        contenteditable="true"
                        @blur="updateWindData($event, indexLigne, indexColonne, 'wind')"
                        @click="toggleColor( indexLigne, indexColonne, 'wind')"
                    >
                      {{ value ? value : '-' }}
                    </span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="pagebreak"> </div>
        </div>
        <div v-if="ceils.needRain">
          <span>
          Cumul de précipitations (en mm)
          <span v-if="ceils.rain">&gt; <span contenteditable="true" @blur="updateAlertData($event, 'rain')">{{ceils.rain}}</span>mm</span>
          <span v-if="ceils.rain"> pendant <span contenteditable="true" @blur="updateAlertData($event, 'rainDuration')">{{ceils.rainDuration}}</span>h</span>
          entre
          <span contenteditable="true" @blur="updateAlertData($event, 'startRainTime')">{{ceils.startRainTime}}</span>h et
          <span contenteditable="true" @blur="updateAlertData($event, 'endRainTime')">{{ceils.endRainTime}}</span>h
        </span>
          <div>
            <table>
              <tbody>
              <tr v-for="(ligne, indexLigne) in rainMatrix"
                  :key="'ligne-' + indexLigne"
                  :style="coloredLines(rainMatrix[indexLigne][0],rainMatrix[indexLigne][1]) ? 'background-color: rgb(166,201,236);' : ''"
              >
                <td
                    v-for="(value, indexColonne) in ligne"
                    :key="'colonne-' + indexColonne"
                    @click="toggleColor(indexLigne, indexColonne, 'rain')"
                    :style="getBackgroundColor(indexLigne, indexColonne, 'rain')"
                >
                    <span
                        contenteditable="true"
                        @blur="updateRainData($event, indexLigne, indexColonne,'rain')"
                        @click="toggleColor( indexLigne, indexColonne, 'rain')"
                    >
                      {{ value ? value : '-' }}
                    </span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import moment from 'moment-timezone';
import "moment-ferie-fr";
moment.locale('fr')
import axios from 'axios';

moment.locale('fr')
// import data from '@/assets/station_data.json'
export default {
  name: 'HomeView',

  data: () => ({
    error:false,
    dataIDPoste:null,
    days: 2,
    dailyData: [],
    moment: moment,
    entreprise:{
      user_company: "Société",
      user_adresse: "Adresse",
      user_cp: "CP",
      user_ville: "Ville"
    },
    stationInfos:{
      NOM: "Station",
      ID: "Station",
      LATITUDE: "Station",
      LONGITUDE: "Station",
    },

    ceils: { // valeurs par défaut
      needTemp:null,
      needWind:null,
      needRain:null,
      rain: null,
      tempmax: null,
      tempmin:null,
      wind: null,
      rainDuration:1,
      windDuration:1,
      startTempTime:7,
      startWindTime:7,
      startRainTime:7,
      endTempTime:17,
      endWindTime:17,
      endRainTime:17,
    },
    temperatureMatrix:[],
    rainMatrix:[],
    windMatrix:[],
    clickedCells: {}
  }),
  methods: {

    toggleColor( indexLigne, indexColonne, type) {
      const cellId = `${indexLigne}-${indexColonne}-${type}`;
      this.$set(this.clickedCells, cellId, !this.clickedCells[cellId]);
    },
    getBackgroundColor(indexLigne, indexColonne, type) {
      const cellId = `${indexLigne}-${indexColonne}-${type}`;
      return this.clickedCells[cellId] ? 'background-color: rgb(247,199,172);' : '';
    },

    coloredLines(day,date) {
      const dateMoment = moment(date, "DD/MM/YYYY");
      return day === 'S' || day === 'D' || moment(dateMoment).isFerie();
    },

    deleteBackGroundColor(type){

      const keys = Object.keys(this.clickedCells);

      // Filtrer les clés qui se terminent par la terminaison spécifiée
      const keysToDelete = keys.filter(key => key.endsWith(type));

      // Supprimer les clés filtrées de l'objet
      keysToDelete.forEach(key => {
        delete this.clickedCells[key];
      });

    },

    updateAlertData(event, name) {
      const newValue = event.target.textContent;
      // Utilisation de la notation de propriété dynamique pour accéder à la propriété
      this.ceils[name] = parseFloat(this.replaceCommaByDot(newValue));
      if (name==='startTempTime'||name==='endTempTime'){
        this.temperatureMatrix = this.filterByTimeType(this.dataIDPoste, this.ceils.startTempTime,this.ceils.endTempTime, "Température");
      }
      if (name==='startWindTime'||name==='endWindTime'){
        this.windMatrix =this.filterByTimeType(this.dataIDPoste, this.ceils.startWindTime, this.ceils.endWindTime, "Vent");
      }
      if (name==='startRainTime'||name==='endRainTime'){
        this.rainMatrix =this.filterByTimeType(this.dataIDPoste, this.ceils.startRainTime, this.ceils.endRainTime, "Pluie");
      }
      if (name==='tempmin'||name==='tempmax'||name==='startTempTime'||name==='endTempTime'){
        this.deleteBackGroundColor('temp')
        this.checkAlert(this.temperatureMatrix,"T")
      }
      if (name==='wind'||name==='windDuration'||name==='startWindTime'||name==='endWindTime'){
        this.deleteBackGroundColor('wind')
        this.checkAlert(this.windMatrix,"FXI")
      }
      if (name==='rain'||name==='rainDuration'||name==='startRainTime'||name==='endRainTime'){
        this.deleteBackGroundColor('rain')
        this.checkAlert(this.rainMatrix,"RR1")
      }

    },

    updateTempData(event, indexLigne, indexColonne) {
      // Récupérer la nouvelle valeur de l'élément modifié
      const newValue = event.target.textContent;

      // Convertir la nouvelle valeur en un nombre à virgule flottante
      const floatValue = parseFloat(this.replaceCommaByDot(newValue));

      if (!isNaN(floatValue) && indexLigne!==0) {
        // Mettre à jour la valeur dans la matrice temperatureMatrix avec la valeur convertie
        this.$set(this.temperatureMatrix[indexLigne], indexColonne, this.replaceDotByComma(floatValue));
        let min = floatValue;
        let max = floatValue;
        // Mettre à jour la valeur minimal et max dans la matrice
        for (let i = 2; i < (this.temperatureMatrix[indexLigne].length)-2; i++) {
          const currentElement = parseFloat(this.replaceCommaByDot(this.temperatureMatrix[indexLigne][i]));
          if (min > currentElement) {
            min =currentElement
          }
          if (max < currentElement) {
            max =currentElement
          }
        }
        this.$set(this.temperatureMatrix[indexLigne], this.temperatureMatrix[indexLigne].length - 2, this.replaceDotByComma(min));
        this.$set(this.temperatureMatrix[indexLigne], this.temperatureMatrix[indexLigne].length - 1, this.replaceDotByComma(max));
      } else {
        console.error("La conversion en float a échoué.");
        // Gérer le cas où la conversion échoue
      }
    },

    updateWindData(event, indexLigne, indexColonne) {

      // Récupérer la nouvelle valeur de l'élément modifié
      const newValue = event.target.textContent;
      console.log("newValue :",newValue)
      if (newValue===''){
        this.$set(this.rainMatrix[indexLigne], indexColonne,'-');
      }

      // Convertir la nouvelle valeur en un nombre à virgule flottante
      const floatValue = parseFloat(this.replaceCommaByDot(newValue));

      if (!isNaN(floatValue) && indexLigne!==0) {
        // Mettre à jour la valeur dans la matrice temperatureMatrix avec la valeur convertie
        this.$set(this.windMatrix[indexLigne], indexColonne, this.replaceDotByComma(floatValue));
        let max = floatValue;

        // Mettre à jour la valeur minimal et max dans la matrice
        for (let i = 2; i < (this.windMatrix[indexLigne].length)-1; i++) {
          const currentElement = parseFloat(this.replaceCommaByDot(this.windMatrix[indexLigne][i]));
          if (max < currentElement) {
            max =currentElement
          }
        }
        this.$set(this.windMatrix[indexLigne], this.windMatrix[indexLigne].length - 1, this.replaceDotByComma(max));
      } else {
        console.error("La conversion en float a échoué.");
      }
    },

    updateRainData(event, indexLigne, indexColonne) {
      // Récupérer la nouvelle valeur de l'élément modifié
      const newValue = event.target.textContent;
      console.log("newValue :",newValue)
      if (newValue===''){
        this.$set(this.rainMatrix[indexLigne], indexColonne,'-');
      }

      // Convertir la nouvelle valeur en un nombre à virgule flottante
      const floatValue = parseFloat(this.replaceCommaByDot(newValue));

      if (!isNaN(floatValue) && indexLigne!==0) {
        // Mettre à jour la valeur dans la matrice temperatureMatrix avec la valeur convertie
        this.$set(this.rainMatrix[indexLigne], indexColonne, this.replaceDotByComma(floatValue));
        let total = 0;

        // Mettre à jour la valeur minimal et max dans la matrice
        for (let i = 2; i < (this.rainMatrix[indexLigne].length)-1; i++) {
          const currentElement = parseFloat(this.replaceCommaByDot(this.rainMatrix[indexLigne][i]));
          total+=currentElement;
        }
        this.$set(this.rainMatrix[indexLigne], this.rainMatrix[indexLigne].length - 1, this.replaceDotByComma(total));
      } else {
        console.error("La conversion en float a échoué.");
      }
    },

    replaceCommaByDot(str) {
      // Vérifie si la chaîne contient une virgule
      if (typeof str === 'string' && str.includes(',')) {
        // Remplace la virgule par un point
        return str.replace(',', '.');
      }
      // Si la chaîne ne contient pas de virgule, retourne la chaîne telle quelle
      return str;
    },
    replaceDotByComma(float) {
      // Convertit le nombre à virgule flottante en chaîne de caractères
      const str = float.toString();

      // Vérifie si la chaîne contient un point
      if (str.includes('.')) {
        // Remplace le point par une virgule
        return str.replace('.', ',');
      }

      // Si la chaîne ne contient pas de point, retourne la chaîne telle quelle
      return str;
    },


    async getClient()
    {
      let id = this.$route.params.id_user
      let jwt;
      //console.log(this.$route.query);
      if ((this.$route.query.tmin!=='null') || (this.$route.query.tmax!=='null')) this.ceils.needTemp =1;
      if (this.$route.query.pluie!=='null') this.ceils.needRain =1;
      if (this.$route.query.vent!=='null') this.ceils.needWind =1;
      if (this.$route.query.tmax==="null") this.$route.query.tmax=null;
      if (this.$route.query.tmin==="null") this.$route.query.tmin=null;
      if (this.$route.query.vent==="null") this.$route.query.vent=null;
      if ((this.$route.query.seuil_tmax!=="null") && (this.$route.query.seuil_tmax!==null))  this.ceils.tempmax=this.$route.query.seuil_tmax;
      if ((this.$route.query.seuil_tmin!=="null") && (this.$route.query.seuil_tmin!==null))  this.ceils.tempmin=this.$route.query.seuil_tmin;
      if ((this.$route.query.seuil_pluie!=="null") && (this.$route.query.seuil_pluie!==null)) this.ceils.rain=this.$route.query.seuil_pluie;
      if ((this.$route.query.seuil_vent!=="null") && (this.$route.query.seuil_vent!==null)) this.ceils.wind=this.$route.query.seuil_vent;
      if ((this.$route.query.heure_debut!=="null") && (this.$route.query.heure_debut!==null)) this.ceils.startTempTime=this.$route.query.heure_debut;
      if ((this.$route.query.heure_debut!=="null") && (this.$route.query.heure_debut!==null)) this.ceils.startWindTime=this.$route.query.heure_debut;
      if ((this.$route.query.heure_debut!=="null") && (this.$route.query.heure_debut!==null)) this.ceils.startRainTime=this.$route.query.heure_debut;
      if ((this.$route.query.heure_fin!=="null") && (this.$route.query.heure_fin!==null)) this.ceils.endTempTime=this.$route.query.heure_fin;
      if ((this.$route.query.heure_fin!=="null") && (this.$route.query.heure_fin!==null)) this.ceils.endWindTime=this.$route.query.heure_fin;
      if ((this.$route.query.heure_fin!=="null") && (this.$route.query.heure_fin!==null)) this.ceils.endRainTime=this.$route.query.heure_fin;

      if(this.$route.query.jwt){
        jwt = this.$route.query.jwt
      }else{
        jwt = localStorage.getItem('jwt')
      }
      let data = await axios.get(`https://api-my.previmeteo.net/users/${id}`, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })
      this.entreprise = data.data
    },

    async getStationObs() {
      let id_poste = this.$route.params.id_station
      let {debut, fin} = this.$route.query
      fin = moment(fin, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD');
      const query = `https://microservices-proxy.vercel.app/api/observation/publithequeMF/getHistorique.php?log=previ&pass=1783&type=horaire&search=${id_poste}&debut=${debut}&fin=${fin}`;
      let data = await axios.get(query)
      //console.log(data.data)
      // if data.data is empty, there is an error
      if (data.data.length === 0) {
        this.error = true
        return
      }
      console.log("data :",data)

      this.dataIDPoste = data.data[id_poste].data

      this.temperatureMatrix =this.filterByTimeType(this.dataIDPoste, this.ceils.startTempTime,this.ceils.endTempTime, "Température");
      this.windMatrix =this.filterByTimeType(this.dataIDPoste, this.ceils.startWindTime, this.ceils.endWindTime, "Vent");
      this.rainMatrix =this.filterByTimeType(this.dataIDPoste, this.ceils.startRainTime, this.ceils.endRainTime, "Pluie");

      console.log("response :",this.temperatureMatrix);

      let nbJour = moment(fin).diff(moment(debut), 'days')
      //console.log('nbJour', nbJour)
      if(!data.data[id_poste])
      {
        data.data = {}
        data.data[id_poste] = {}
        data.data[id_poste].data = []
        for (let i = 0; i < nbJour; i++) {
          let date = moment(debut).add(i, 'days')
          data.data[id_poste].data.push({
            DATE: date.format('YYYY-MM-DD'),
            TN: 'NA',
            TX: 'NA',
            RR: 'NA',
            FXI: 'NA',
          })
        }
      }
      this.stationInfos = data.data[id_poste].desc[0]
      this.dailyData = [];
      data.data[id_poste].data.map(map => {
        map.day = moment(map.DATE).format('YYYY-MM-DD');
        map.date = moment(map.DATE).format('DD/MM/YYYY');
        map.tmin = map.TN !== null ? map.TN : "-";
        map.temp = map.TN !== null ? map.TN : "-";
        map.tmax = map.TX !== null ? map.TX : "-"
        map.wind = map.FXI !== null ? Math.round((map.FXI * 3.6) * 10) / 10 : "-";
        map.rain = map.RR !== null ? map.RR : "-";
      });

      console.log('preformated data', data.data);

      let groupedByDay = data.data[id_poste].data.reduce((r, a) => {
        r[a.day] = [...r[a.day] || [], a];
        return r;
      }, {});
      //console.log(groupedByDay);
      // Get Max for each day and insert in a new array
      for (const key of Object.entries(groupedByDay)) {
        //console.log(key)
        // get max for each parameter, rain, temp, wind
        // add cumulated rain for the day
        let max = {
          date: key[0],
          rain: key[1].reduce((a, b) => a + parseFloat(b.rain), 0),
          temp: Math.max(...key[1].map(o => o.temp)),
          tmax: Math.max(...key[1].map(o => o.tmax)),
          tmin: Math.max(...key[1].map(o => o.tmin)),
          wind: Math.max(...key[1].map(o => o.wind))
        }
        this.dailyData.push(max);
      }

      console.log('dailyData ? ', this.dailyData)
    },

    filterByTimeType(data, heureDebut, heureFin, type) {
      type=this.convertType(type)
      if (type !== 'T' && type !== 'FXI' && type !== 'RR1') {
        console.error('Type '+type+' invalide. Les types valides sont "T", "FXI" et "RR1".');
        return [];
      }
      const debut = parseInt(heureDebut);
      const fin =  parseInt(heureFin);
      const convertedTimeZoneData = this.convertUTCToParisTime(data)
      let donneesFiltrees = this.timeFilter(convertedTimeZoneData,debut,fin)
      // Créer un nouveau tableau avec les valeurs DATE et le type spécifié
      const reduceData = donneesFiltrees.map(item => ({
        DATE: item.DATE,
        [type]: item[type]?item[type]:"Pas de données"
      }));
      const firstLine = this.createFirstLine(reduceData,debut,fin,type)

      let maintab = this.mainTab(reduceData,type,debut,fin)
      maintab.unshift(firstLine)
      this.checkAlert(maintab,type)

      maintab =this.convertToString(maintab)

      return maintab;
    },
    checkAlert(maintab,type){
      if (type==='T'){
        for (let indexLigne = 1; indexLigne < (maintab.length); indexLigne++) {
          // Parcourir chaque colonne de la ligne actuelle
          for (let indexColonne = 2; indexColonne < (maintab[indexLigne].length)-2; indexColonne++) {
            // Vérifier si le nombre est supérieur à tempmax
            const floatValue = parseFloat(this.replaceCommaByDot(maintab[indexLigne][indexColonne]));
            if (floatValue > this.ceils.tempmax && this.ceils.tempmax!==null && maintab[indexLigne][0]!=='S' && maintab[indexLigne][0]!=='D' && !moment(moment(maintab[indexLigne][1], "DD/MM/YYYY")).isFerie()) {
              // Exécuter la fonction toggleColor avec les indices de ligne et de colonne, ainsi que le type
              this.toggleColor(indexLigne, indexColonne, 'temp');
            }
            if (floatValue < this.ceils.tempmin && this.ceils.tempmin!==null && maintab[indexLigne][0]!=='S' && maintab[indexLigne][0]!=='D' && !moment(moment(maintab[indexLigne][1], "DD/MM/YYYY")).isFerie()) {
              // Exécuter la fonction toggleColor avec les indices de ligne et de colonne, ainsi que le type
              this.toggleColor(indexLigne, indexColonne, 'temp');
            }
          }
        }
      }
      if (type==='RR1'){
        for (let indexLigne = 1; indexLigne < (maintab.length); indexLigne++) {
          // Parcourir chaque colonne de la ligne actuelle
          for (let indexColonne = 2; indexColonne < (maintab[indexLigne].length)-1; indexColonne++) {
            // Vérifier si le nombre est supérieur à tempmax
            let cumulRain = parseFloat(this.replaceCommaByDot(maintab[indexLigne][indexColonne]));
            for (let i =1 ;i<this.ceils.rainDuration; i++){
              if (indexColonne+i<maintab[indexLigne].length - 1){
                cumulRain+=parseFloat(this.replaceCommaByDot(maintab[indexLigne][indexColonne+i]))
              }
            }
            if (cumulRain > this.ceils.rain && this.ceils.rain!==null && maintab[indexLigne][0]!=='S' && maintab[indexLigne][0]!=='D' && parseFloat(this.replaceCommaByDot(maintab[indexLigne][indexColonne]))!== 0 && !moment(moment(maintab[indexLigne][1], "DD/MM/YYYY")).isFerie()) {
              // Exécuter la fonction toggleColor avec les indices de ligne et de colonne, ainsi que le type
              for (let i =0 ;i<this.ceils.rainDuration; i++){
                if (indexColonne+i<maintab[indexLigne].length - 1 && this.getBackgroundColor(indexLigne, indexColonne+i, 'rain')===''){
                  this.toggleColor(indexLigne, indexColonne+i, 'rain');
                }
              }
            }
          }
        }
      }
      if (type==='FXI'){
        for (let indexLigne = 1; indexLigne < (maintab.length); indexLigne++) {
          for (let indexColonne = 2; indexColonne < (maintab[indexLigne].length)-1; indexColonne++) {
            const windTab = [];
            for (let i =0 ;i<this.ceils.windDuration; i++){
              if (indexColonne+i<maintab[indexLigne].length - 1){
                windTab.push(parseFloat(this.replaceCommaByDot(maintab[indexLigne][indexColonne+i])))
              }
            }
            if (windTab.every(nombre => nombre > this.ceils.wind) && this.ceils.wind!==null && windTab.length===this.ceils.windDuration && maintab[indexLigne][0]!=='S' && maintab[indexLigne][0]!=='D' && !moment(moment(maintab[indexLigne][1], "DD/MM/YYYY")).isFerie()) {
              // Exécuter la fonction toggleColor avec les indices de ligne et de colonne, ainsi que le type
              for (let i =0 ;i<this.ceils.windDuration; i++){
                if (indexColonne+i<maintab[indexLigne].length - 1 && this.getBackgroundColor(indexLigne, indexColonne+i, 'wind')===''){
                  this.toggleColor(indexLigne, indexColonne+i, 'wind');
                }
              }
            }
          }
        }
      }
    },

    convertType(type) {
      if (type === 'Température') {
        return 'T';
      } else if (type === 'Vent') {
        return 'FXI';
      } else if (type === 'Pluie') {
        return 'RR1';
      } else {
        return type;
      }
    },

    convertUTCToParisTime(data) {
      const targetTimeZone = 'Europe/Paris';

      // Convertir les dates UTC
      const parisTimes = data.map(item => moment.utc(item.DATE).tz(targetTimeZone));

      // Formater les dates et les fusionner avec les données d'origine
      return data.map((item, index) => ({
        ...item,
        DATE: parisTimes[index].format('YYYY-MM-DD HH:mm:ss'),
      }));
    },

    timeFilter(data, debut,fin){
      return data.filter(item => {
        // Convertir l'heure de l'élément en objet Date
        const heureElement = new Date(item.DATE);
        const heure = heureElement.getHours();
        return heure >= debut && heure <= fin;
      })
    },

    createFirstLine(data, debut, fin, type){
      // Création de la première ligne
      const heures = ["Jours","Date"];
      // Boucler à travers les heures et les ajouter au tableau
      for (let i = debut; i <= fin; i++) {
        heures.push(`${i}h`);
      }
      if (type!=='T'){
        heures.push(type === 'RR1' ? 'Total' :'Max.');
      }else {
        heures.push('Min.','Max.');
      }
      return heures
    },

    getDayOfWeek(dateString) {
      const daysOfWeek = ['D','L', 'M', 'M', 'J', 'V', 'S'];
      const date = new Date(dateString);
      const dayIndex = date.getDay();
      return daysOfWeek[dayIndex];
    },

    convertToString(matrix) {
      // Parcours de la matrice
      return matrix.map(row => {
        return row.map(col => {
          // Vérification du type de l'élément
          if (!isNaN(parseFloat(col)) && isFinite(col)) {
            // Si c'est un nombre, le convertir en chaîne de caractères et remplacer le point par une virgule
            return col.toString().replace('.', ',');
          } else {
            // Si ce n'est pas un nombre, retourner l'élément tel quel
            return col;
          }
        });
      });
    },

    mainTab(data,type,debut,fin){
      const matrix = [];
      let currentDay = null;
      let currentRow = [];
      let cptTime=debut;

      // Parcours des données
      data.forEach(item => {
        const date = item.DATE.split(' ')[0]; // Obtenir la date uniquement
        const time = parseInt(item.DATE.split(' ')[1].split(':')[0]);
        let value = parseFloat(item[type]); // Convertir la température en nombre
        if (type ==="FXI"){
          value*=3.6
          value=parseFloat(value.toFixed(1))
        }
        // Si c'est le premier élément ou si la date change
        if (!currentDay || currentDay !== date) {

          //inséré à la fin du tableau
          if(currentDay){
            if (cptTime!==fin+1){
              for (let i = 0; i < fin+1-cptTime; i++) {
                currentRow.push("")
              }
            }
            if (type ==="FXI"){
              const nombres = currentRow.filter(element => typeof element === 'number');
              const nombresPropres = nombres.filter(nombre => !isNaN(nombre));
              const maxValue = Math.max(...nombresPropres);
              currentRow.push(maxValue.toFixed(1));
            }
            if (type ==="RR1"){
              const nombres = currentRow.filter(element => typeof element === 'number');
              let sum=0;
              for (const i in nombres) {
                sum+=nombres[i]
              }
              currentRow.push(sum.toFixed(1));
            }
            if (type ==="T"){
              const nombres = currentRow.filter(element => typeof element === 'number');
              const nombresPropres = nombres.filter(nombre => !isNaN(nombre));
              const minValue = Math.min(...nombresPropres);
              const maxValue = Math.max(...nombresPropres);
              currentRow.push(minValue.toFixed(1),maxValue.toFixed(1));
            }
          }
          cptTime=debut

          if (currentRow.length > 0) {
            matrix.push(currentRow); // Ajouter la ligne précédente à la matrice
          }
          const day = this.getDayOfWeek(item.DATE)
          //affiche la date auformat DD/MM/AA
          const formateDate =  `${date.split('-')[2]}/${date.split('-')[1]}/${date.split('-')[0].substr(-2)}`
          currentRow = [day,formateDate]; // Créer une nouvelle ligne avec la température actuelle
          if (time !== cptTime){
            for (let i = 0; i < time-cptTime; i++) {
              currentRow.push("")
            }

          }
          currentRow.push(value)
          currentDay = date; // Mettre à jour la date actuelle
          cptTime=time+1
        } else {
          if (time !== cptTime){
            for (let i = 0; i < time-cptTime; i++) {
              currentRow.push("")
            }
          }
          cptTime=time+1
          currentRow.push(value); // Ajouter la température à la ligne actuelle
        }
      });

      // Ajouter la dernière ligne à la matrice
      if (currentRow.length > 0) {
        if (type ==="FXI"){
          const nombres = currentRow.filter(element => typeof element === 'number');
          const nombresPropres = nombres.filter(nombre => !isNaN(nombre));
          const maxValue = Math.max(...nombresPropres);
          currentRow.push(maxValue.toFixed(1));
        }
        if (type ==="RR1"){
          const nombres = currentRow.filter(element => typeof element === 'number');
          let sum=0;
          for (const i in nombres) {
            sum+=nombres[i]
          }
          currentRow.push(sum.toFixed(1));
        }
        if (type ==="T"){
          const nombres = currentRow.filter(element => typeof element === 'number');
          const nombresPropres = nombres.filter(nombre => !isNaN(nombre));
          const minValue = Math.min(...nombresPropres);
          const maxValue = Math.max(...nombresPropres);
          currentRow.push(minValue.toFixed(1),maxValue.toFixed(1));
        }
        matrix.push(currentRow);
      }
      return matrix;
    },

  },
  mounted() {
    this.getClient();
    this.getStationObs();
  }
}
</script>

<style scoped>

@media print {
  .pagebreak {
    page-break-before: always;
    padding-top: 10px;
  } /* page-break-after works, as well */
}

input[type="text"] {
  min-width: 10px; /* Largeur minimale de 10px */
  max-width: 150px; /* Largeur maximale de 150px */
  width: auto; /* La largeur s'adapte à la taille du texte, jusqu'à la largeur maximale */
  box-sizing: border-box; /* Inclut la bordure et le padding dans la largeur totale */
}
table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 2px solid #000000;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
}
</style>