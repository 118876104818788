import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '../store'
import HomeScheduleView from "@/views/HomeScheduleView.vue";
Vue.use(VueRouter)

const isLogged = (to, from, next) => {
  if(store.state.user){
    next()
  }else{
    let uriWithQuery = to.fullPath
    store.dispatch('setRedirectUri', uriWithQuery)
    next('/login')
  }
}


const routes = [
  {
    path: '/:id_user/:id_station',
    name: 'home',
    component: HomeView,
    beforeEnter: isLogged
  },
  {
    path: '/horaire/:id_user/:id_station',
    name: 'home',
    component: HomeScheduleView,
    beforeEnter: isLogged
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  {
    path: '',
    name: 'form',
    component: () => import(/* webpackChunkName: "about" */ '../views/FormView.vue'),
    beforeEnter: isLogged
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
