import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user:localStorage.getItem('user') || null,
    jwt: localStorage.getItem('jwt') || null,
    redirect_uri: null
  },
  getters: {
  },
  mutations: {
    SET_USER(state, user){
      state.user = user
      localStorage.setItem('user', user)
    },
    SET_JWT(state, jwt){
      state.jwt = jwt
      localStorage.setItem('jwt', jwt)
    },
    SET_REDIRECT_URI(state, redirect_uri){
      state.redirect_uri = redirect_uri
    }
  },
  actions: {
    setUser({commit}, user){
      commit('SET_USER', user)
    },
    setJwt({commit}, jwt){
      commit('SET_JWT', jwt)
    },
    setRedirectUri({commit}, redirect_uri){
      commit('SET_REDIRECT_URI', redirect_uri)
    }
  },
  modules: {
  }
})
