<template>
  <AutomatedKDBSchedule />
</template>

<script>
import AutomatedKDBSchedule from '../components/AutomatedKDBSchedule.vue'

export default {
  name: 'HomeScheduleView',

  components: {
    AutomatedKDBSchedule,
  },
}
</script>
