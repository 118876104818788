<template>
  <v-container>
    <v-row v-if="!error">
      <v-col>
        <img width="200px" src="/img/logopm.png" /><br/>
        87 Quai des Queyries<br />
        33100 Bordeaux<br />
        05 53 93 43 06<br />
      </v-col>
      <v-col class="pt-12">
        <b contenteditable="true">{{ entreprise.user_company }}</b><br />

        <span contenteditable="true"> {{ entreprise.user_adresse  }}</span><br/>
        <span contenteditable="true"> {{ entreprise.user_cp  }}</span>
        <span contenteditable="true"> {{ entreprise.user_ville  }}</span>
      </v-col>
      <v-col cols="12">
        <b>Période</b> :
        <!-- Get date range period inside dailyData -->
        {{ moment(dailyData[0].date).format('DD-MM-YYYY') }} au {{ moment(dailyData[dailyData.length -
            1].date).format('DD-MM-YYYY')
        }}
        <br />
        <b>Chantier</b> : <span contenteditable="true">{{ $route.query.chantierName }}</span><br />
        <span v-if="$route.query?.reference"><b>Vos réf.</b> : <span contenteditable="true">{{ $route.query.reference }}</span><br /></span>
        <b>Station</b> : <span contenteditable="true">{{ stationInfos.NOM }} ({{ $route.params.id_station }})</span><br />
      </v-col>
      <v-col cols="12">
        <v-simple-table dense>
          <thead>
            <tr>
              <th>Jour</th>
              <th>Date</th>
              <th>Cumul des précipitations (en mm)</th>
              <th v-if="$route.query?.tmin">Température minimale sous abri (en °C)</th>
              <th v-if="$route.query?.tmax">Température maximale sous abri (en °C)</th>
              <th v-if="$route.query?.vent">Vitesse maximale du vent (en km/h)</th>
              <th>Intempéries</th>
            </tr>
          </thead>
          <tbody>

            <!-- TR background blue if day is on the week -->
            <tr v-for="i in dailyData" :key="`key${i}`"
              :style="{ 'background-color': moment(i.date).format('dddd') === 'samedi' || moment(i.date).format('dddd') === 'dimanche' || moment(i.date).isFerie() ? 'rgb(210 212 255)' : '#ffffff' }">

              <td>{{ moment(i.date).format('dddd') }}</td>
              <td>{{ moment(i.date).format('DD/MM/YYYY') }}
              </td>
              <td :style="{ 'background-color': i.rain >= ceils.rain ? moment(i.date).format('dddd') !== 'samedi' && moment(i.date).format('dddd') !== 'dimanche' && !moment(i.date).isFerie() ? 'rgb(255 182 184)' : '' : '' }">
                <v-text-field hide-details type="text" v-model="i.rain" />
              </td>
              <td  v-if="$route.query?.tmin" :style="{ 'background-color': i.tmin <= ceils.tempmin || i.tmin >= ceils.temp ?  moment(i.date).format('dddd') !== 'samedi' && moment(i.date).format('dddd') !== 'dimanche' && !moment(i.date).isFerie() ? 'rgb(255 182 184)' : '' : '' }">
                <v-text-field hide-details type="text" v-model="i.tmin" />
              </td>
              <td  v-if="$route.query?.tmax" :style="{ 'background-color': i.tmax >= ceils.tempmax || i.tmax >= ceils.tempmax ?  moment(i.date).format('dddd') !== 'samedi' && moment(i.date).format('dddd') !== 'dimanche' && !moment(i.date).isFerie() ? 'rgb(255 182 184)' : '' : '' }">
                <v-text-field hide-details type="text" v-model="i.tmax" />
              </td>
              <td  v-if="$route.query?.vent" :style="{ 'background-color': i.wind >= ceils.wind ?  moment(i.date).format('dddd') !== 'samedi' && moment(i.date).format('dddd') !== 'dimanche' && !moment(i.date).isFerie() ? 'rgb(255 182 184)' : '' : '' }">
                <v-text-field hide-details type="text" v-model="i.wind" />
              </td>
              <td class="text-center"
                :style="{ 'background-color': i.rain >= ceils.rain || (i.tmin <= ceils.tempmin && $route.query?.tmin) || (i.tmax >= ceils.tempmax && $route.query?.tmax) || (i.wind >= ceils.wind && $route.query?.vent) ? moment(i.date).format('dddd') !==  'samedi' && moment(i.date).format('dddd') !==  'dimanche' && !moment(i.date).isFerie() ? 'rgb(255 182 184)' : '' : '' }">
                {{ i.rain >= ceils.rain || (i.tmin <= ceils.tempmin && $route.query?.tmin) || (i.tmax >= ceils.tempmax && $route.query?.tmax) || (i.wind >= ceils.wind && $route.query?.vent) ? moment(i.date).format('dddd') !==  'samedi' && moment(i.date).format('dddd') !==  'dimanche' && !moment(i.date).isFerie() ? '1' : '' : '' }}
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td  v-if="$route.query?.tmin"></td>
              <td  v-if="$route.query?.tmax"></td>
              <td  v-if="$route.query?.vent"></td>
              <td class="font-weight-bold text-center">Total</td>
              <td class="font-weight-bold text-center">
                <!-- Total of Intempéries -->
                {{ dailyData.filter(i => (i.rain >= ceils.rain || (i.tmin <= ceils.tempmin && $route.query?.tmin) || (i.tmax >= ceils.tempmax && $route.query?.tmax) || (i.wind >= ceils.wind && $route.query?.vent)) && moment(i.date).format('dddd') !==  'samedi' && moment(i.date).format('dddd') !==  'dimanche' && !moment(i.date).isFerie() ).length }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
      <v-col cols="12">

      <div class="pagebreak"> </div>
      </v-col>

      
      <v-col>
        <img width="200px" src="/img/logopm.png" /><br/>
        87 Quai des Queyries<br />
        33100 Bordeaux<br />
        05 53 93 43 06<br />
      </v-col>
      <v-col class="pt-12">
        <b>{{ entreprise.user_company }}</b><br />
        <span contenteditable="true"> {{ entreprise.user_adresse  }}</span><br/>
        <span contenteditable="true"> {{ entreprise.user_cp  }}</span>
        <span contenteditable="true"> {{ entreprise.user_ville  }}</span>
      </v-col>
     
<v-col cols="12">
        <b>Période</b> :
        <!-- Get date range period inside dailyData -->
        {{ moment(dailyData[0].date).format('DD-MM-YYYY') }} au {{ moment(dailyData[dailyData.length -
            1].date).format('DD-MM-YYYY')
        }}
        <br />
        <b>Chantier</b> : <span contenteditable="true">{{ $route.query.chantierName }}</span><br />
        <span v-if="$route.query?.reference"><b>Vos réf.</b> : <span contenteditable="true">{{ $route.query.reference }}</span><br /></span>
        <b>Station</b> : <span contenteditable="true">{{ stationInfos.NOM }} ({{ $route.params.id_station }})</span><br />
      </v-col>
      <v-col>
        <p>
          <strong>Calcul des intempéries sur les jours ouvrés</strong> : {{ dailyData.filter(i => (i.rain >= ceils.rain || (i.tmin <= ceils.tempmin && $route.query?.tmin) || (i.tmax >= ceils.tempmax && $route.query?.tmax) || (i.wind >= ceils.wind && $route.query?.vent))  && moment(i.date).format('dddd') !==  'samedi' && moment(i.date).format('dddd') !==  'dimanche' && !moment(i.date).isFerie() ) .length
          }} jour{{ dailyData.filter(i => (i.rain >= ceils.rain || (i.tmin <= ceils.tempmin && $route.query?.tmin) || (i.tmax >= ceils.tempmax && $route.query?.tmax) || (i.wind >= ceils.wind && $route.query?.vent))  && moment(i.date).format('dddd') !==  'samedi' && moment(i.date).format('dddd') !==  'dimanche' && !moment(i.date).isFerie() ) .length > 1 ? 's' : ''
}}, 
dont 
              <span>{{ dailyData.filter(i => i.rain >= ceils.rain && moment(i.date).format('dddd') !==  'samedi' && moment(i.date).format('dddd') !==  'dimanche' && !moment(i.date).isFerie()).length }} jour{{
    dailyData.filter(i => i.rain >= ceils.rain && moment(i.date).format('dddd') !==  'samedi' && moment(i.date).format('dddd') !==  'dimanche' && !moment(i.date).isFerie() ).length > 1 ? 's' : ''
}} avec au moins {{ ceils.rain }} mm de pluie, </span>
              <span  v-if="$route.query?.tmin">{{ dailyData.filter(i => i.tmin <= ceils.tempmin && moment(i.date).format('dddd') !==  'samedi' && moment(i.date).format('dddd') !==  'dimanche' && !moment(i.date).isFerie() ).length }} jour{{ dailyData.filter(i => i.tmin <= ceils.tempmin && moment(i.date).format('dddd') !==  'samedi' && moment(i.date).format('dddd') !==  'dimanche' && !moment(i.date).isFerie() ).length > 1 ? 's':'' }} avec des températures inférieures ou égales à {{ ceils.tempmin }}°C, </span>
              <span  v-if="$route.query?.tmax">{{ dailyData.filter(i => i.tmax >= ceils.tempmax && moment(i.date).format('dddd') !==  'samedi' && moment(i.date).format('dddd') !==  'dimanche' && !moment(i.date).isFerie() ).length }} jour{{ dailyData.filter(i => i.tmax >= ceils.tempmax && moment(i.date).format('dddd') !==  'samedi' && moment(i.date).format('dddd') !==  'dimanche' && !moment(i.date).isFerie() ).length > 1 ? 's':'' }} avec des températures supérieures ou égales à {{ ceils.tempmax }}°C,</span>
               <span  v-if="$route.query?.vent"> 
               {{ dailyData.filter(i => i.wind >= ceils.wind && moment(i.date).format('dddd') !==  'samedi' && moment(i.date).format('dddd') !==  'dimanche' && !moment(i.date).isFerie() ).length }} jour{{ dailyData.filter(i => i.wind >= ceils.wind && moment(i.date).format('dddd') !==  'samedi' && moment(i.date).format('dddd') !==  'dimanche' && !moment(i.date).isFerie() ).length > 1 ? 's':'' }} avec des rafales de vent supérieures ou égales à {{ ceils.wind }} km/h</span>.
        </p>
        <v-simple-table border="1">
          <thead>
            <tr>
              <th>Paramètres</th>
              <th>Seuils</th>
              <th>Nombre de jours</th>
            </tr>
          </thead>
          <tbody>
            <!-- count the number of day for each parameters -->
            <tr>
              <td>Pluie</td>
              <td><v-text-field hide-details type="number" v-model="ceils.rain" >
                <template slot="append">mm</template>    
              </v-text-field> </td>
              <td class="font-weight-bold text-center">{{ dailyData.filter(i => i.rain >= ceils.rain && moment(i.date).format('dddd') !==  'samedi' && moment(i.date).format('dddd') !==  'dimanche' && !moment(i.date).isFerie() ).length }}</td>
            </tr>
            <tr  v-if="$route.query?.tmin">
              <td >Température min</td>
              <td><v-text-field hide-details type="number" v-model="ceils.tempmin" >
                <template slot="append">°C</template>    
              </v-text-field></td>
              <td class="font-weight-bold text-center">{{ dailyData.filter(i => i.tmin <= ceils.tempmin && moment(i.date).format('dddd') !==  'samedi' && moment(i.date).format('dddd') !==  'dimanche' && !moment(i.date).isFerie() ).length }}</td>
            </tr>
            <tr  v-if="$route.query?.tmax">
              <td>Température max</td>
              <td><v-text-field hide-details type="number" v-model="ceils.tempmax" >
                <template slot="append">°C</template>    
              </v-text-field>
              </td>
              <td class="font-weight-bold text-center">{{ dailyData.filter(i => i.tmax >= ceils.tempmax && moment(i.date).format('dddd') !==  'samedi' && moment(i.date).format('dddd') !==  'dimanche' && !moment(i.date).isFerie() ).length }}</td>
            </tr>
            <tr  v-if="$route.query?.vent">
              <td>Vent</td>
              <td><v-text-field hide-details type="number" v-model="ceils.wind" >
                <template slot="append">km/h</template>    
              </v-text-field></td>
              <td class="font-weight-bold text-center">{{ dailyData.filter(i => i.wind >= ceils.wind && moment(i.date).format('dddd') !==  'samedi' && moment(i.date).format('dddd') !==  'dimanche' && !moment(i.date).isFerie() ).length }}</td>
            </tr>

          </tbody>
        </v-simple-table>
      </v-col>
      <v-col cols="12">
        <!-- Base text : 
        Vos seuils d'intempéries :* pluie sup ou = à 10 mm
            * t° minimale inf ou = à 0°C
            * t° maximale  sup ou = à 30°C
            * rafales de vent > ou = 50 km/h -->
       

          Vos seuils d'intempéries :
        <ul>
          <li>pluie &gt; ou = à {{ ceils.rain }} mm <small>(Le cumul de pluie est mesuré de 6h le matin à 6h le lendemain matin. )</small></li>
          <li  v-if="$route.query?.tmin">t° minimale &lt; ou = à {{ ceils.tempmin }}°C <small>(Les températures sont mesurées de
            minuit à minuit chaque jour.)</small></li>
          <li  v-if="$route.query?.tmax">t° maximale &gt; ou = à {{ ceils.tempmax }}°C <small>(Les températures sont mesurées de
            minuit à minuit chaque jour.)</small></li>
          <li  v-if="$route.query?.vent">rafales de vent &gt; ou = {{ ceils.wind }} km/h <small>(Le vent est mesuré de
            minuit à minuit chaque jour.)</small></li>
        </ul>   
      </v-col>
      <v-col cols="6"> <p>Fait à Bordeaux, le {{ moment().format("DD MMMM YYYY") }} </p></v-col>
      <v-col cols="6" class="py-0">
        <img width="200px" src="/img/signature.jpeg" />
      </v-col>
    </v-row>
    <v-col cols="12" v-if="error">  
      <v-alert type="error" dismissible>
        Une erreur s'est produite lors du chargement des données. Des données peuvent être manquantes.
      </v-alert>
    </v-col>
  </v-container>
</template>

<script>
import moment from 'moment';
import "moment-ferie-fr"
moment.locale('fr')
import axios from 'axios';
// import data from '@/assets/station_data.json'
export default {
  name: 'HomeView',

  data: () => ({
    error:false,
    days: 2,
    dailyData: [],
    moment: moment,
    entreprise:{
      user_company: "Société",
      user_adresse: "Adresse",
      user_cp: "CP",
      user_ville: "Ville"
    },  
    stationInfos:{
      NOM: "Station",
      ID: "Station",
      LATITUDE: "Station",
      LONGITUDE: "Station",
    },  
    ceils: { // valeurs par defaut
      rain: 5,
      tempmax: 32,
      tempmin:0,
      wind: 60
    }
  }),
  methods: {

    async getClient()
    {
      let id = this.$route.params.id_user
      let jwt;
      //console.log(this.$route.query);
      if (this.$route.query.tmax=="null") this.$route.query.tmax=null;
      if (this.$route.query.tmin=="null") this.$route.query.tmin=null;
      if (this.$route.query.vent=="null") this.$route.query.vent=null;
      if ((this.$route.query.seuil_tmax!="null") & (this.$route.query.seuil_tmax!==null))  this.ceils.tempmax=this.$route.query.seuil_tmax;
      if ((this.$route.query.seuil_tmin!="null") & (this.$route.query.seuil_tmin!==null))  this.ceils.tempmin=this.$route.query.seuil_tmin;
      if ((this.$route.query.seuil_pluie!="null") & (this.$route.query.seuil_pluie!==null)) this.ceils.rain=this.$route.query.seuil_pluie;
      if ((this.$route.query.seuil_vent!="null") & (this.$route.query.seuil_vent!==null)) this.ceils.wind=this.$route.query.seuil_vent;
            
      if(this.$route.query.jwt){
        jwt = this.$route.query.jwt
      }else{
        jwt = localStorage.getItem('jwt')
      }
      let data = await axios.get(`https://api-my.previmeteo.net/users/${id}`, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })
      this.entreprise = data.data
    },  
    async getStationObs() {
      let id = this.$route.params.id_station
      let {debut, fin} = this.$route.query
      let data = await axios.get(`https://microservices-proxy.vercel.app/api/observation/publithequeMF/getHistorique.php?log=previ&pass=1783&search=${id}&debut=${debut}&fin=${fin}`)
      //console.log(data.data)
      // if data.data is empty, there is an error
      if (data.data.length == 0) {
        this.error = true
        return
      }
      let nbJour = moment(fin).diff(moment(debut), 'days')
      //console.log('nbJour', nbJour)
      if(!data.data[id])
      {
        data.data = {}
        data.data[id] = {}
        data.data[id].data = []
        for (let i = 0; i < nbJour; i++) {
          let date = moment(debut).add(i, 'days')
          data.data[id].data.push({
            DATE: date.format('YYYY-MM-DD'),
            TN: '-',
            TX: '-',
            RR: '-',
            FXI: '-',
          })
        }
      }
      this.stationInfos = data.data[id].desc[0]
      this.dailyData = [];
      data.data[id].data.map(map => {
        map.day = moment(map.DATE).format('YYYY-MM-DD');
        map.date = moment(map.DATE).format('DD/MM/YYYY');
        map.tmin = map.TN !== null ? map.TN : "-";
        map.temp = map.TN !== null ? map.TN : "-";
        map.tmax = map.TX !== null ? map.TX : "-"
        map.wind = map.FXI !== null ? Math.round((map.FXI * 3.6) * 10) / 10 : "-";
        map.rain = map.RR !== null ? map.RR : "-";
      });

      console.log('preformated data', data.data);

      let groupedByDay = data.data[id].data.reduce((r, a) => {
        r[a.day] = [...r[a.day] || [], a];
        return r;
      }, {});
      //console.log(groupedByDay);
      // Get Max for each day and insert in a new array 
      for (const key of Object.entries(groupedByDay)) {
        //console.log(key)
        // get max for each parameters, rain, temp, wind
        // add cumulated rain for the day
        let max = {
          date: key[0],
          rain: key[1].reduce((a, b) => a + parseFloat(b.rain), 0),
          temp: Math.max(...key[1].map(o => o.temp)),
          tmax: Math.max(...key[1].map(o => o.tmax)),
          tmin: Math.max(...key[1].map(o => o.tmin)),
          wind: Math.max(...key[1].map(o => o.wind))
        }
        this.dailyData.push(max);
      }
      // find missing days and add them with NA values
      let missingDays = []
      for (let i = 0; i < nbJour; i++) {
        let date = moment(debut).add(i, 'days')
        let day = date.format('YYYY-MM-DD')
        if (!this.dailyData.find(o => o.date === day)) {
          console.log('add missing')
          missingDays.push({
            date: day,
            rain: '-',
            temp: '-',
            tmin: '-',
            tmax: '-',
            wind: '-'
          })
        }
      }
      //console.log('missingDays', missingDays)
      this.dailyData = [
        ...this.dailyData,
        ...missingDays
      ]
      //console.log('dailyData ? ', this.dailyData)
    }
  },
  mounted() {
    this.getClient();
    this.getStationObs();
  }
}
</script>

<style>
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
    border: none;
}

@media print {
    .pagebreak { 
      page-break-before: always;
      padding-top: 10px;
     } /* page-break-after works, as well */
}

.v-input {
    margin: 0px;
    padding: 0px;
    height: auto;
}

td {
    border: 1px solid #a3a3a3;
}
</style>