<template>
  <AutomatedKDB />
</template>

<script>
  import AutomatedKDB from '../components/AutomatedKDB'

  export default {
    name: 'HomeView',

    components: {
      AutomatedKDB,
    },
  }
</script>
